import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, FormikProps } from 'formik';
import { Button, ButtonContainer, Fieldset, Form, Paragraph, SectionHeading } from '@vwfs-bronson/bronson-react';
import { preventSubmit } from '@cp-shared-8/frontend-ui';
import { ContractDescriptionBO } from '@cp-uk/common';
import {
    buildEarliestAvailableTimeSelectItems,
    buildLatestAvailableTimeSelectItems,
    buildPhoneNumberSelectItems,
    textWithComponents,
} from 'utils';
import { View } from 'components/view/View';
import { ContractDescription } from 'components/contract-description/ContractDescription';
import { ValidatedSelect } from 'components/validated-select/ValidatedSelect';
import { ValidatedCheckboxGroup } from 'components/validated-checkbox-group/ValidatedCheckboxGroup';
import { ArrearsBalanceParagraph } from '../arrears-balance-paragraph/ArrearsBalanceParagraph';
import { AlternativeNotification } from '../alternative-notification/AlternativeNotification';
import { buildInitialValues, buildSelections } from './helpers';
import { validationSchema } from './ContactDetailsValidation';
import { AvailableTimes, ContactDetailsFormValues, ContactDetailsSelections } from './types';

export type ContactDetailsViewProps = {
    contractDescription: ContractDescriptionBO;
    totalArrears: number | undefined;
    mobileNumber: string | undefined;
    homePhoneNumber: string | undefined;
    workPhoneNumber: string | undefined;
    currentOptionTitle: string;
    currentSelections: ContactDetailsSelections | undefined;
    onBack: () => void;
    onContinue: (selections: ContactDetailsSelections) => void;
};

export const ContactDetailsView: React.FC<ContactDetailsViewProps> = ({
    contractDescription,
    totalArrears,
    mobileNumber,
    homePhoneNumber,
    workPhoneNumber,
    currentOptionTitle,
    currentSelections,
    onBack,
    onContinue,
}) => {
    const { t } = useTranslation('request-additional-help-contact-details-view');

    // TODO: Analytics...

    const phoneNumberSelectItems = buildPhoneNumberSelectItems(t, mobileNumber, homePhoneNumber, workPhoneNumber);

    const availableTimeEntries = Object.entries(AvailableTimes);
    const earliestAvailableTimeSelectItems = buildEarliestAvailableTimeSelectItems(t, availableTimeEntries);
    const latestAvailableTimeSelectItems = buildLatestAvailableTimeSelectItems(t, availableTimeEntries);

    const initialValues = buildInitialValues(currentSelections);

    const onBackClick = (): void => {
        // TODO: Analytics...
        onBack();
    };

    return (
        <View testId={'contactDetailsView'}>
            <SectionHeading level={2} testId={'viewHeader'}>
                {t('subHeading', { currentOptionTitle })}
            </SectionHeading>
            <ArrearsBalanceParagraph totalArrears={totalArrears} />
            <ContractDescription className={'u-mb'} contractDescription={contractDescription} />
            <Paragraph testId={'introduction1Paragraph'}>{textWithComponents(t, 'paragraphs.introduction1')}</Paragraph>
            <Paragraph testId={'introduction2Paragraph'}>{t('paragraphs.introduction2')}</Paragraph>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema(t, t('openingHours.saturdayOpening.closingTime'))}
                onSubmit={(values): void => {
                    const selections = buildSelections(values);

                    onContinue(selections);
                }}
            >
                {(formik: FormikProps<ContactDetailsFormValues>): React.ReactNode => (
                    <Form className={'uk-request-additional-help-contact-details-form'} onSubmit={preventSubmit}>
                        <Fieldset>
                            <Fieldset.Row>
                                <ValidatedSelect
                                    className={'select-phone-number'}
                                    label={t('phoneNumber.label')}
                                    placeholder={t('phoneNumber.placeholder')}
                                    selectItems={phoneNumberSelectItems}
                                    name={'phoneNumber'}
                                    testId={'phoneNumber'}
                                    isMandatory
                                />
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ValidatedSelect
                                    className={'select-time'}
                                    label={t('earliestAvailableTime.label')}
                                    placeholder={t('earliestAvailableTime.placeholder')}
                                    selectItems={earliestAvailableTimeSelectItems}
                                    tooltip={t('earliestAvailableTime.tooltip')}
                                    name={'earliestAvailableTime'}
                                    testId={'earliestAvailableTime'}
                                    isMandatory
                                />
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ValidatedSelect
                                    className={'select-time'}
                                    label={t('latestAvailableTime.label')}
                                    placeholder={t('latestAvailableTime.placeholder')}
                                    selectItems={latestAvailableTimeSelectItems}
                                    tooltip={t('latestAvailableTime.tooltip')}
                                    name={'latestAvailableTime'}
                                    testId={'latestAvailableTime'}
                                    isMandatory
                                />
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ValidatedCheckboxGroup
                                    className={'u-indent'}
                                    label={t('availableDays.label')}
                                    tooltip={t('availableDays.tooltip')}
                                    name={'availableDays'}
                                    testId={'availableDays'}
                                    withHtmlValidationWarning
                                >
                                    {Object.keys(formik.values.availableDays).map((key) => (
                                        <ValidatedCheckboxGroup.Checkbox
                                            key={key}
                                            className={'u-mb-xsmall'}
                                            label={t(`availableDays.${key}.label`)}
                                            groupName={'availableDays'}
                                            name={`availableDays.${key}`}
                                            testId={`availableDays.${key}`}
                                        />
                                    ))}
                                </ValidatedCheckboxGroup>
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ButtonContainer nav>
                                    <Button type={'button'} testId={'backButton'} onClick={onBackClick} secondary>
                                        {t('translation:editableSectionNav.back')}
                                    </Button>
                                    <Button type={'button'} testId={'continueButton'} onClick={formik.submitForm}>
                                        {t('translation:editableSectionNav.continue')}
                                    </Button>
                                </ButtonContainer>
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <AlternativeNotification />
                            </Fieldset.Row>
                        </Fieldset>
                    </Form>
                )}
            </Formik>
        </View>
    );
};
