export enum AvailableTimes {
    noPreferredEarliest = 'noPreferredEarliest',
    nineAm = '9H',
    tenAm = '10H',
    elevenAm = '11H',
    twelvePm = '12H',
    onePm = '13H',
    twoPm = '14H',
    threePm = '15H',
    fourPm = '16H',
    fivePm = '17H',
    sixPm = '18H',
    sevenPm = '19H',
    noPreferredLatest = 'noPreferredLatest',
}

export type AvailableDays = {
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
};

export type ContactDetailsFormValues = {
    phoneNumber: string;
    earliestAvailableTime: string;
    latestAvailableTime: string;
    availableDays: AvailableDays;
};

export type ContactDetailsSelections = {
    phoneNumber: string;
    earliestAvailableTime: string;
    latestAvailableTime: string;
    availableDays: AvailableDays;
};
