import { TFunction } from 'i18next';
import { ValidatedSelectItem } from 'components/validated-select/ValidatedSelect';

const buildCommonAvailableTimeSelectItems = (
    t: TFunction,
    entries: [string, string][],
    mode: 'earliest' | 'latest',
): ValidatedSelectItem[] => {
    const args: number[] = mode === 'earliest' ? [0, -2] : [2];
    return entries.slice(...args).map(([, value]) => {
        return {
            label: t(`availableTimes.${value}`),
            value,
        };
    });
};

export const buildEarliestAvailableTimeSelectItems = (
    t: TFunction,
    entries: [string, string][],
): ValidatedSelectItem[] => {
    return buildCommonAvailableTimeSelectItems(t, entries, 'earliest');
};

export const buildLatestAvailableTimeSelectItems = (
    t: TFunction,
    entries: [string, string][],
): ValidatedSelectItem[] => {
    return buildCommonAvailableTimeSelectItems(t, entries, 'latest');
};
