import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik, FormikProps } from 'formik';
import {
    Button,
    ButtonContainer,
    Fieldset,
    Form,
    Heading,
    Modal,
    Paragraph,
    UnorderedList,
} from '@vwfs-bronson/bronson-react';
import {
    preventSubmit,
    TrackingId,
    useAnalyticsActionTracker,
    useAnalyticsPageViewTracker,
} from '@cp-shared-8/frontend-ui';
import {
    ContractDescriptionBO,
    FinanceBrandsEOC,
    getRequestRefinanceQuoteEndpoint,
    ProductTypesEOC,
    RequestRefinanceQuote,
} from '@cp-uk/common';
import { CpDataApi } from 'cp-xhr';
import { View } from 'components/view/View';
import {
    buildEarliestAvailableTimeSelectItems,
    buildLatestAvailableTimeSelectItems,
    buildLinkToPhoneNumber,
    buildPhoneNumberSelectItems,
    contractCategory,
    textWithComponents,
    useFinanceCompanyDetails,
} from 'utils';
import { dashboardPagePath } from 'components/navigation/paths';
import { carOrVehicleText } from 'components/helpers';
import { ContractDescription } from 'components/contract-description/ContractDescription';
import {
    ContactDetailsNotification,
    ContactDetailsNotificationMode,
    getContactDetailsNotificationMode,
} from 'components/notifications/contact-details/ContactDetailsNotification';
import { ValidatedCheckbox } from 'components/validated-checkbox/ValidatedCheckbox';
import { ValidatedCheckboxGroup } from 'components/validated-checkbox-group/ValidatedCheckboxGroup';
import { ValidatedInputWithCleave } from 'components/validated-input/ValidatedInput';
import { ValidatedSelect, ValidatedSelectItem } from 'components/validated-select/ValidatedSelect';
import { SolutionsRefinanceViewFormValues, RefinanceMonths, AvailableTimes } from './types';
import { refinanceMonthsToNumber, buildCallbackAvailabilityString } from './helpers';
import {
    maxValidDepositAmount,
    maxValidMonthlyBudget,
    minValidDepositAmount,
    minValidMonthlyBudget,
    validationSchema,
} from './SolutionsRefinanceValidation';

export type SolutionsRefinanceViewProps = {
    encryptedContractId: string;
    contractDescription: ContractDescriptionBO;
    financeBrand: FinanceBrandsEOC;
    vehicleBrand: string;
    firstName: string | undefined;
    lastName: string | undefined;
    emailAddress: string | undefined;
    mobileNumber: string | undefined;
    homePhoneNumber: string | undefined;
    workPhoneNumber: string | undefined;
    onBack: () => void;
};

export const SolutionsRefinanceView: React.FC<SolutionsRefinanceViewProps> = ({
    encryptedContractId,
    contractDescription,
    financeBrand,
    vehicleBrand,
    firstName,
    lastName,
    emailAddress,
    mobileNumber,
    homePhoneNumber,
    workPhoneNumber,
    onBack,
}) => {
    const productType: ProductTypesEOC = 'solutions';

    const [trackingId, setTrackingId] = useState<TrackingId>('endOfContractPcpRefinance');
    const { phoneNumber, openingHours } = useFinanceCompanyDetails(productType, financeBrand);
    const { t } = useTranslation('end-of-contract-solutions-refinance-view');
    const history = useHistory();

    const { onAction: onBackAction } = useAnalyticsActionTracker('onEndOfContractPcpRefinanceBack');
    const { onAction: onRequestAction } = useAnalyticsActionTracker('onEndOfContractPcpRefinanceRequest');
    useAnalyticsPageViewTracker(trackingId);

    const pound = '£';
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
    const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

    const contactDetailsNotificationMode = getContactDetailsNotificationMode(
        emailAddress,
        mobileNumber,
        homePhoneNumber,
        workPhoneNumber,
    );
    if (contactDetailsNotificationMode !== ContactDetailsNotificationMode.CheckContactDetails) {
        return <ContactDetailsNotification mode={contactDetailsNotificationMode} />;
    }

    const linkToPhoneNumber = buildLinkToPhoneNumber(phoneNumber, 'brand');
    const carOrVehicle = carOrVehicleText(financeBrand);

    const phoneNumberSelectItems = buildPhoneNumberSelectItems(t, mobileNumber, homePhoneNumber, workPhoneNumber);

    const buildRefinanceMonthsSelectItems = (): ValidatedSelectItem[] => {
        return Object.entries(RefinanceMonths).map(([, value]) => {
            return {
                label: t(`refinanceMonths.${value}`),
                value,
            };
        });
    };

    const availableTimeEntries = Object.entries(AvailableTimes);
    const earliestAvailableTimeSelectItems = buildEarliestAvailableTimeSelectItems(t, availableTimeEntries);
    const latestAvailableTimeSelectItems = buildLatestAvailableTimeSelectItems(t, availableTimeEntries);

    const initialValues: SolutionsRefinanceViewFormValues = {
        confirmImportantThings: false,
        phoneNumber: '',
        refinanceMonths: '',
        depositAmount: '0',
        monthlyBudget: '',
        earliestAvailableTime: AvailableTimes.noPreferredEarliest,
        latestAvailableTime: AvailableTimes.noPreferredLatest,
        availableDays: {
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false,
        },
    };

    const onSuccessModalConfirm = (): void => {
        history.push(dashboardPagePath());
    };

    const onErrorModalConfirm = (): void => {
        setShowErrorModal(false);
        setTrackingId('endOfContractPcpRefinance');
    };

    const onBackClick = (): void => {
        onBackAction();
        onBack();
    };

    return (
        <View testId={'solutionsRefinanceView'}>
            <Heading level={2} testId={'viewHeader'}>
                {t('subHeading', { contractCategory: contractCategory(productType), vehicleBrand })}
            </Heading>
            <ContractDescription className={'u-mb'} contractDescription={contractDescription} />
            <ContactDetailsNotification
                className={'u-mb'}
                mode={contactDetailsNotificationMode}
                emailAddress={emailAddress}
            />
            <Paragraph testId={'importantThingsParagraph'}>{t('paragraphs.importantThings')}</Paragraph>
            <div className={'c-ul c-ul--compact'}>
                <UnorderedList testId={'importantThingsList'}>
                    <UnorderedList.Item data-testid={'item1'}>{t('listItems.importantThings.1')}</UnorderedList.Item>
                    <UnorderedList.Item data-testid={'item2'}>{t('listItems.importantThings.2')}</UnorderedList.Item>
                    <UnorderedList.Item data-testid={'item3'}>{t('listItems.importantThings.3')}</UnorderedList.Item>
                    <UnorderedList.Item data-testid={'item4'}>{t('listItems.importantThings.4')}</UnorderedList.Item>
                    <UnorderedList.Item data-testid={'item5'}>{t('listItems.importantThings.5')}</UnorderedList.Item>
                    <UnorderedList.Item data-testid={'item6'}>{t('listItems.importantThings.6')}</UnorderedList.Item>
                    <UnorderedList.Item data-testid={'item7'}>
                        {t('listItems.importantThings.7', { carOrVehicle })}
                    </UnorderedList.Item>
                </UnorderedList>
            </div>
            <Paragraph testId={'completeFollowingParagraph'}>{t('paragraphs.completeFollowing')}</Paragraph>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema(t, t('openingHours.saturdayOpening.closingTime'))}
                onSubmit={({
                    phoneNumber,
                    refinanceMonths,
                    depositAmount,
                    monthlyBudget,
                    earliestAvailableTime,
                    latestAvailableTime,
                    availableDays,
                }: SolutionsRefinanceViewFormValues): Promise<void> => {
                    const requestRefinanceQuote: RequestRefinanceQuote = {
                        firstName,
                        lastName,
                        emailAddress: emailAddress ?? '',
                        phoneNumber,
                        refinanceMonths: refinanceMonthsToNumber(refinanceMonths),
                        depositAmount: Number(depositAmount),
                        monthlyBudget: Number(monthlyBudget),
                        callbackAvailability: buildCallbackAvailabilityString(
                            t,
                            earliestAvailableTime as AvailableTimes,
                            latestAvailableTime as AvailableTimes,
                            availableDays,
                        ),
                    };
                    onRequestAction();
                    setIsSubmitting(true);
                    return CpDataApi.post<void>(
                        getRequestRefinanceQuoteEndpoint(encryptedContractId),
                        requestRefinanceQuote,
                    )
                        .then(() => {
                            setShowSuccessModal(true);
                            setTrackingId('endOfContractPcpRefinanceSuccess');
                            setIsSubmitting(false);
                        })
                        .catch(() => {
                            setShowErrorModal(true);
                            setTrackingId('endOfContractPcpRefinanceFailure');
                            setIsSubmitting(false);
                        });
                }}
            >
                {(formik: FormikProps<SolutionsRefinanceViewFormValues>): React.ReactNode => (
                    <Form className={'uk-end-of-contract-solutions-refinance-form'} onSubmit={preventSubmit}>
                        <Fieldset>
                            <Fieldset.Row>
                                <ValidatedCheckbox
                                    label={t('confirmImportantThings.label')}
                                    name={'confirmImportantThings'}
                                    testId={'confirmImportantThings'}
                                    isMandatory={true}
                                />
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ValidatedSelect
                                    className={'select-phone-number'}
                                    label={t('phoneNumber.label')}
                                    placeholder={t('phoneNumber.placeholder')}
                                    selectItems={phoneNumberSelectItems}
                                    name={'phoneNumber'}
                                    testId={'phoneNumber'}
                                    isMandatory
                                />
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ValidatedSelect
                                    className={'select-refinance-months'}
                                    label={t('refinanceMonths.label')}
                                    tooltip={t('refinanceMonths.tooltip')}
                                    placeholder={t('refinanceMonths.placeholder')}
                                    selectItems={buildRefinanceMonthsSelectItems()}
                                    name={'refinanceMonths'}
                                    testId={'refinanceMonths'}
                                    isMandatory
                                />
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ValidatedInputWithCleave
                                    cleaveOptions={{
                                        numericOnly: true,
                                        blocks: [5],
                                        delimiter: '',
                                    }}
                                    className={'input-money'}
                                    label={t('depositAmount.label')}
                                    tooltip={t('depositAmount.tooltip', {
                                        minValidDepositAmount,
                                        maxValidDepositAmount,
                                    })}
                                    name={'depositAmount'}
                                    testId={'depositAmount'}
                                    addonText={pound}
                                    reversed
                                    isMandatory
                                />
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ValidatedInputWithCleave
                                    cleaveOptions={{
                                        numericOnly: true,
                                        blocks: [5],
                                        delimiter: '',
                                    }}
                                    className={'input-money'}
                                    label={t('monthlyBudget.label')}
                                    tooltip={t('monthlyBudget.tooltip', {
                                        minValidMonthlyBudget,
                                        maxValidMonthlyBudget,
                                    })}
                                    name={'monthlyBudget'}
                                    testId={'monthlyBudget'}
                                    addonText={pound}
                                    reversed
                                    isMandatory
                                />
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <Paragraph testId={'callbackAvailabilityParagraph'}>
                                    {textWithComponents(t, 'paragraphs.callbackAvailability', undefined, {
                                        openingHours,
                                    })}
                                </Paragraph>
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ValidatedSelect
                                    className={'select-time'}
                                    label={t('earliestAvailableTime.label')}
                                    placeholder={t('earliestAvailableTime.placeholder')}
                                    selectItems={earliestAvailableTimeSelectItems}
                                    tooltip={t('earliestAvailableTime.tooltip')}
                                    name={'earliestAvailableTime'}
                                    testId={'earliestAvailableTime'}
                                    isMandatory
                                />
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ValidatedSelect
                                    className={'select-time'}
                                    label={t('latestAvailableTime.label')}
                                    placeholder={t('latestAvailableTime.placeholder')}
                                    selectItems={latestAvailableTimeSelectItems}
                                    tooltip={t('latestAvailableTime.tooltip')}
                                    name={'latestAvailableTime'}
                                    testId={'latestAvailableTime'}
                                    isMandatory
                                />
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ValidatedCheckboxGroup
                                    className={'u-indent'}
                                    label={t('availableDays.label')}
                                    tooltip={t('availableDays.tooltip')}
                                    name={'availableDays'}
                                    testId={'availableDays'}
                                    withHtmlValidationWarning
                                >
                                    {Object.keys(formik.values.availableDays).map((key) => (
                                        <ValidatedCheckboxGroup.Checkbox
                                            key={key}
                                            className={'u-mb-xsmall'}
                                            label={t(`availableDays.${key}.label`)}
                                            groupName={'availableDays'}
                                            name={`availableDays.${key}`}
                                            testId={`availableDays.${key}`}
                                        />
                                    ))}
                                </ValidatedCheckboxGroup>
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ButtonContainer nav>
                                    <Button
                                        type={'button'}
                                        testId={'backButton'}
                                        disabled={isSubmitting}
                                        onClick={onBackClick}
                                        secondary
                                    >
                                        {t('translation:editableSectionNav.back')}
                                    </Button>
                                    <Button
                                        type={'button'}
                                        testId={'requestButton'}
                                        disabled={isSubmitting}
                                        onClick={formik.submitForm}
                                    >
                                        {t('translation:editableSectionNav.request')}
                                    </Button>
                                </ButtonContainer>
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <Paragraph testId={'contactUsParagraph'} className={'u-mb-none'}>
                                    {textWithComponents(
                                        t,
                                        'paragraphs.contactUs',
                                        { linkToPhoneNumber },
                                        { vehicleBrand, phoneNumber, openingHours },
                                    )}
                                </Paragraph>
                            </Fieldset.Row>
                        </Fieldset>
                    </Form>
                )}
            </Formik>
            <Modal
                shown={showSuccessModal}
                status={'success'}
                title={t('successModal.title')}
                closeAny={false}
                hideCloseButton={false}
                buttonCloseLabel={t('translation:editableSectionNav.close')}
                buttonConfirmText={t('translation:editableSectionNav.ok')}
                buttonConfirmType={'button'}
                testId={'successModal'}
                onClose={onSuccessModalConfirm}
                onConfirm={onSuccessModalConfirm}
            >
                <Paragraph>{textWithComponents(t, 'successModal.contents')}</Paragraph>
            </Modal>
            <Modal
                shown={showErrorModal}
                status={'error'}
                title={t('errorModal.title')}
                closeAny={false}
                hideCloseButton={false}
                buttonCloseLabel={t('translation:editableSectionNav.close')}
                buttonConfirmText={t('translation:editableSectionNav.ok')}
                buttonConfirmType={'button'}
                testId={'errorModal'}
                onClose={onErrorModalConfirm}
                onConfirm={onErrorModalConfirm}
            >
                <Paragraph>{t('errorModal.contents')}</Paragraph>
            </Modal>
        </View>
    );
};
