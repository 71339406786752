import { formatAsDate, inputDateFormat, MethodsOfPayment, parseCPDateFromInput } from '@cp-uk/common';
import 'extensions';
import { PayInOneGoFormValues, PayInOneGoSelections } from './types';

export const buildInitialValues = (selections: PayInOneGoSelections | undefined): PayInOneGoFormValues => {
    if (selections === undefined) {
        return {
            methodOfPayment: '',
            dateOfPayment: '',
        };
    }

    const { methodOfPayment, dateOfPayment } = selections;
    return {
        methodOfPayment: methodOfPayment,
        dateOfPayment: !!dateOfPayment ? formatAsDate(dateOfPayment, inputDateFormat) : '',
    };
};

export const buildSelections = ({ methodOfPayment, dateOfPayment }: PayInOneGoFormValues): PayInOneGoSelections => {
    return {
        methodOfPayment: methodOfPayment as MethodsOfPayment,
        dateOfPayment: !!dateOfPayment ? parseCPDateFromInput(dateOfPayment).toMoment().toUTC().toCPDate() : undefined,
    };
};

export const dateOfPaymentIsMandatory = (methodOfPayment: MethodsOfPayment): boolean => {
    return methodOfPayment === 'cardPayment' || methodOfPayment === 'bankTransfer';
};

export const dateOfPaymentDisabled = (methodOfPayment: MethodsOfPayment): boolean => {
    return !dateOfPaymentIsMandatory(methodOfPayment);
};
