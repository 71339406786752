import { AvailableTimes, ContactDetailsFormValues, ContactDetailsSelections } from './types';

export const buildInitialValues = (selections: ContactDetailsSelections | undefined): ContactDetailsFormValues => {
    if (selections === undefined) {
        return {
            phoneNumber: '',
            earliestAvailableTime: AvailableTimes.noPreferredEarliest,
            latestAvailableTime: AvailableTimes.noPreferredLatest,
            availableDays: {
                monday: false,
                tuesday: false,
                wednesday: false,
                thursday: false,
                friday: false,
                saturday: false,
            },
        };
    }

    const {
        phoneNumber,
        earliestAvailableTime,
        latestAvailableTime,
        availableDays: { monday, tuesday, wednesday, thursday, friday, saturday },
    } = selections;
    return {
        phoneNumber,
        earliestAvailableTime,
        latestAvailableTime,
        availableDays: {
            monday,
            tuesday,
            wednesday,
            thursday,
            friday,
            saturday,
        },
    };
};

export const buildSelections = ({
    phoneNumber,
    earliestAvailableTime,
    latestAvailableTime,
    availableDays: { monday, tuesday, wednesday, thursday, friday, saturday },
}: ContactDetailsFormValues): ContactDetailsSelections => {
    return {
        phoneNumber,
        earliestAvailableTime,
        latestAvailableTime,
        availableDays: {
            monday,
            tuesday,
            wednesday,
            thursday,
            friday,
            saturday,
        },
    };
};
